[class*="sidebar-dark-"] {
    background-color: #1f2b5b;
}

.btn-primary {
    color: #fff;
    background-color: #5080fd;
    border-color: #5080fd;
    box-shadow: none;
}

.btn-primary:hover {
    color: #fff;
    background-color: #3dbdec;
    border-color: #3dbdec;
}

.btn-primary:focus,
.btn-primary.focus {
    color: #fff;
    background-color: #3dbdec;
    border-color: #3dbdec;
    box-shadow: 0 0 0 0 rgba(38, 143, 255, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #5080fd;
    border-color: #5080fd;
}

.btn-outline-primary {
    color: #5080fd;
    border-color: #5080fd;
  }

  .btn-outline-primary:hover {
    color: #fff;
    background-color: #5080fd;
    border-color: #5080fd;
  }

  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.5);
  }

  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #5080fd;
    background-color: transparent;
  }

  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #5080fd;
    border-color: #5080fd;
  }

  .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.5);
  }

.bg-primary {
    background-color: #5080fd !important;
}

.text-primary {
    color: #5080fd !important;
}
.card-primary.card-outline {
    border-top: 3px solid #5080fd;
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #5080fd;
    border-color: #5080fd;
}

a {
    color: #5080fd;
    text-decoration: none;
    background-color: transparent;
}

a.text-muted:hover {
    color: #5080fd !important;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #5080fd;
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #5080fd;
    border-color: #5080fd;
}

.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active,
.sidebar-light-primary .nav-sidebar > .nav-item > .nav-link.active {
  background-color: #5080fd;
  color: #fff;
}

.sidebar-dark-primary .nav-sidebar.nav-legacy > .nav-item > .nav-link.active,
.sidebar-light-primary .nav-sidebar.nav-legacy > .nav-item > .nav-link.active {
  border-color: #5080fd;
}